import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import PageTitle from '../components/pagetitle';
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="adtech for developers" />
    <PageTitle>Hi!</PageTitle>
    <p>This site is just launched January 2020 and we only got four pages:</p>
    <h3>Google Cloud</h3>
    <p>
      <Link to="/google-cloud/storage-cheat-sheet">Google Storage Cheet Sheet</Link><br />
      <Link to="/google-cloud/functions-cheat-sheet">Google Cloud Functions Cheet Sheet</Link>
    </p>
    <h3>Search Engine Optimization</h3>
    <p>
      <Link to="/seo/adding-site-to-google">Automatically add a site map to Google and Bing</Link>
    </p>
    <h3>Golang</h3>
    <p>
      <Link to="/golang/struct-cheat-sheet">Go structs</Link>
    </p>

    <div style={{ maxWidth: `500px`, marginBottom: `2.45rem` }}>
      <Image />
    </div>
  </Layout>
)

export default IndexPage
